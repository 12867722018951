import React from 'react';
import { Navbar } from '../components';

import '../styles/globals.css';
// test comment
function TestPage() {
  return (
    <>
      <Navbar />
    </>
  );
}

export default TestPage;
